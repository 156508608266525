<template>
  <component :is="layout">
    <div class="fadeInUp" role="main">
      <div class="row fadeInUp" role="main">
        <div class="col-12 col-md-3">
          <ConfigurationNav />
        </div>
        <div class="col-12 col-md-9">
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-header">
              <h5>Create questionnaire</h5>
            </div>
            <div class="card-body">
              <form class="form" @submit.prevent="onSubmit">
                <div class="form-group">
                  <div class="form-group col-md-12">
                    <label for="name" class="control-label">Questionnaire name</label>
                    <input
                      required
                      id="name"
                      class="form-control"
                      v-bind:class="{ error: hasErrors.name }"
                      type="text"
                      name="name"
                      maxlength="100"
                      v-model="name"
                    />
                    <span
                      v-show="errors.has('name')"
                      class="invalid-feedback"
                      >{{ errors.first("name") }}</span
                    >
                  </div>
                </div>
                <ButtonLoading
                  :loading="loading"
                  :class="'btn-primary float-right'"
                  :title="'Create'"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
export default {
  name: "FieldGroupConfigCreate",
  components: {
    Default,
    ButtonLoading,
    ConfigurationNav,
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      name: "",
      key: "",
      hasErrors: {},
    };
  },
  methods: {
    convertToKey(text) {
      /*eslint-disable */
      return text
        .toLowerCase()
        .replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "")
        .replace(/ /gi, "-");
      /*eslint-enable */
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const key = this.convertToKey(this.name);
          jsonAPI
            .post("questionnaire/config", {
              name: this.name,
              key: key,
            })
            .then((response) => {
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "Update success",
                type: "success",
              });
              this.$router.push({
                name: "config.forms.edit",
                params: { id: response.data.id },
              });
            })
            .catch((error) => {
              console.log(error);
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "validation.error",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
